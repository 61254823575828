var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Details" }, [
    _c(
      "div",
      { staticClass: "warp" },
      [
        _c("div", { staticClass: "details_contant" }, [
          _c("div", { staticClass: "title" }, [
            _vm.type in { news: 1, expo: 1 }
              ? _c("h2", [
                  _vm._v(
                    _vm._s(_vm.$t("lb_mediaInfo_bar1")) +
                      " / " +
                      _vm._s(_vm.$t(_vm.detailTypeList[_vm.type]))
                  )
                ])
              : _vm._e(),
            _c("div", { staticClass: "main_title" }, [
              _c("h2", [_vm._v(_vm._s(_vm.title))])
            ])
          ]),
          _c("div", { staticClass: "active_details" }, [
            _c("div", { domProps: { innerHTML: _vm._s(_vm.content) } })
          ])
        ]),
        _c("div", { staticClass: "footers" }, [
          _c("div", { staticClass: "left" }, [
            _c("div", { staticClass: "Shareto" }, [
              _vm._v(_vm._s(_vm.$t("Share.title")))
            ]),
            _c("ul", [
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "http://service.weibo.com/share/share.php?url=" +
                        _vm.currentUrl() +
                        "&pic=" +
                        _vm.imgURL +
                        "&title=" +
                        _vm.title
                    }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/images/Summit/icon_xinlang@2x.png",
                        alt: ""
                      }
                    })
                  ]
                )
              ]),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://www.facebook.com/sharer.php?u=" +
                        _vm.currentUrl(),
                      target: "_blank"
                    }
                  },
                  [
                    _c("img", {
                      attrs: { src: "/images/Summit/icon_f@2x.png", alt: "" }
                    })
                  ]
                )
              ]),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://twitter.com/intent/tweet?url=" +
                        _vm.currentUrl() +
                        "&via=conmartofficial&text=" +
                        _vm.title,
                      target: "_blank"
                    }
                  },
                  [
                    _c("img", {
                      attrs: { src: "/images/Summit/icon_gezi@2x.png", alt: "" }
                    })
                  ]
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "right" }, [
            _c("a", { staticClass: "Return", on: { click: _vm.Return } }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.$t("Share.back")) +
                  "\n                "
              )
            ])
          ])
        ]),
        _c("Footer")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }